/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Answer {
  question_id: string;
  answer: string;
}

export interface AnthropicRequest {
  report_type: string;
}

export interface CurrentQuestionRequest {
  current_question_id: string;
}

/**
 * * `1` - Yes
 * * `2` - No
 * * `3` - Unsure
 */
export enum HasDiagnosedEnum {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

/**
 * * `1` - Yes
 * * `2` - No
 */
export enum IsCurrentDietManagementEnum {
  Value1 = 1,
  Value2 = 2,
}

export interface Login {
  email: string;
  password: string;
}

export interface Nested {
  id: number;
  /** @maxLength 200 */
  name: string;
  /** @maxLength 900 */
  medical_category: string;
  symptoms: Nested[];
  food_categories_to_avoid: Nested[];
  food_categories_to_eat: Nested[];
  food_to_avoid: Nested[];
  food_to_eat: Nested[];
}

export type NullEnum = null;

export interface PatchedUserProfile {
  id?: number;
  user?: User;
  impact_on_life_quality?: string;
  comfort_level?: string;
  severity_of_symptoms?: string;
  completed_user_survey?: boolean;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  has_diagnosed?: HasDiagnosedEnum | NullEnum | null;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  proceed_with_general_assessment?: IsCurrentDietManagementEnum | NullEnum | null;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  is_correlation_symptoms_and_food?: IsCurrentDietManagementEnum | NullEnum | null;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  is_current_diet_management?: IsCurrentDietManagementEnum | NullEnum | null;
  current_diet_management?: string;
  diagnosed_conditions?: Nested[];
  allergic_food?: Nested[];
  symptoms?: Nested[];
  food_intolerances?: Nested[];
}

export interface QuestionResponse {
  id: string;
  name: string;
  type: string;
  options: string[];
  /** @format double */
  progress: number;
}

export interface ReportContent {
  /** The content of the report as a JSON object. */
  content: any;
}

export interface SignupError {
  error: Record<string, string[]>;
}

export interface User {
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
  /** @maxLength 150 */
  first_name?: string;
  /** @maxLength 150 */
  last_name?: string;
}

export interface UserProfile {
  id: number;
  user: User;
  impact_on_life_quality: string;
  comfort_level: string;
  severity_of_symptoms: string;
  completed_user_survey?: boolean;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  has_diagnosed?: HasDiagnosedEnum | NullEnum | null;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  proceed_with_general_assessment?: IsCurrentDietManagementEnum | NullEnum | null;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  is_correlation_symptoms_and_food?: IsCurrentDietManagementEnum | NullEnum | null;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  is_current_diet_management?: IsCurrentDietManagementEnum | NullEnum | null;
  current_diet_management?: string;
  diagnosed_conditions: Nested[];
  allergic_food: Nested[];
  symptoms: Nested[];
  food_intolerances: Nested[];
}

export interface UserReportResponse {
  /** A dictionary with dynamic keys representing report types. */
  report_data: Record<string, ReportContent>;
}

export interface UserResponse {
  user: UserProfile;
}

export interface UserSignup {
  /** @maxLength 150 */
  first_name?: string;
  /** @maxLength 150 */
  last_name?: string;
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
  /** @maxLength 128 */
  password: string;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Zenbeli API
 * @version 1.0.0
 *
 * Zenbeli - Search
 */
export class ZenbeliBackendApi<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags csrf_token
     * @name CsrfTokenCreate
     * @request POST:/api/csrf_token/
     * @secure
     */
    csrfTokenCreate: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/csrf_token/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags get_next_question
     * @name GetNextQuestionRetrieve
     * @request GET:/api/get_next_question/
     * @secure
     */
    getNextQuestionRetrieve: (params: RequestParams = {}) =>
      this.request<QuestionResponse, any>({
        path: `/api/get_next_question/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags get_previous_question
     * @name GetPreviousQuestionCreate
     * @request POST:/api/get_previous_question/
     * @secure
     */
    getPreviousQuestionCreate: (data: CurrentQuestionRequest, params: RequestParams = {}) =>
      this.request<QuestionResponse, any>({
        path: `/api/get_previous_question/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags login
     * @name LoginCreate
     * @request POST:/api/login/
     */
    loginCreate: (data: Login, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/login/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags logout
     * @name LogoutCreate
     * @request POST:/api/logout/
     * @secure
     */
    logoutCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/logout/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags pdf_export
     * @name PdfExportCreate
     * @request POST:/api/pdf_export/
     * @secure
     */
    pdfExportCreate: (params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/api/pdf_export/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt
     * @name PromptCreate
     * @request POST:/api/prompt/
     * @secure
     */
    promptCreate: (data: AnthropicRequest, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/prompt/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags reset_survey
     * @name ResetSurveyCreate
     * @request POST:/api/reset_survey/
     * @secure
     */
    resetSurveyCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/reset_survey/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags save_answer
     * @name SaveAnswerCreate
     * @request POST:/api/save_answer/
     * @secure
     */
    saveAnswerCreate: (data: Answer, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/save_answer/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags signup
     * @name SignupCreate
     * @request POST:/api/signup/
     */
    signupCreate: (data: UserSignup, params: RequestParams = {}) =>
      this.request<void, SignupError>({
        path: `/api/signup/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-profile
     * @name UserProfileRetrieve
     * @request GET:/api/user-profile/
     * @secure
     */
    userProfileRetrieve: (params: RequestParams = {}) =>
      this.request<UserResponse, any>({
        path: `/api/user-profile/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-profile
     * @name UserProfileUpdate
     * @request PUT:/api/user-profile/
     * @secure
     */
    userProfileUpdate: (data: UserProfile, params: RequestParams = {}) =>
      this.request<UserProfile, any>({
        path: `/api/user-profile/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-profile
     * @name UserProfilePartialUpdate
     * @request PATCH:/api/user-profile/
     * @secure
     */
    userProfilePartialUpdate: (data: PatchedUserProfile, params: RequestParams = {}) =>
      this.request<UserProfile, any>({
        path: `/api/user-profile/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-profile
     * @name UserProfileDestroy
     * @request DELETE:/api/user-profile/
     * @secure
     */
    userProfileDestroy: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/user-profile/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user_reports
     * @name UserReportsRetrieve
     * @request GET:/api/user_reports/
     * @secure
     */
    userReportsRetrieve: (params: RequestParams = {}) =>
      this.request<UserReportResponse, void>({
        path: `/api/user_reports/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
